<template>
  <section
    class="mb-7"
    style="margin-top: -64px"
  >
    <v-row>
      <v-col
        cols="12"
        md="4"
      >
        <v-hover close-delay="20">
          <template v-slot="{ hover }">
            <v-card
              :elevation="hover ? 2 : 8"
              hover
            >
              <div class="pa-3">
                <v-img
                  :aspect-ratio="16 / 9"
                  :src="require('@/assets/Main/nursery.webp')"
                  contain
                />
              </div>

              <v-fade-transition>
                <v-overlay
                  v-if="hover"
                  absolute
                  class="text-right"
                  color="rgb(230, 230, 230)"
                >
                  <v-slide-x-transition appear>
                    <v-btn
                      class="subheading font-weight-light text-capitalize mx-0 mt-3"
                      color="secondary"
                      depressed
                      large
                      to="/retail-nursery"
                    >
                      Explore
                    </v-btn>
                  </v-slide-x-transition>
                </v-overlay>
              </v-fade-transition>
              <center>
                <div class="pa-3 thirdary text-center white--text">
                  Retail Nursery
                </div>
              </center>
            </v-card>
          </template>
        </v-hover>
      </v-col>

      <!-- wine tasting -->
      <v-col
        cols="12"
        md="4"
      >
        <v-hover close-delay="20">
          <template v-slot="{ hover }">
            <v-card
              :elevation="hover ? 2 : 8"
              hover
            >
              <div class="pa-3">
                <v-img
                  :aspect-ratio="16 / 9"
                  :src="require('@/assets/Main/plant-guide.webp')"
                  contain
                />
              </div>

              <v-fade-transition>
                <v-overlay
                  v-if="hover"
                  absolute
                  class="text-right"
                  color="rgb(230, 230, 230)"
                >
                  <v-slide-x-transition appear>
                    <v-btn
                      class="subheading font-weight-light text-capitalize mx-0 mt-3"
                      color="secondary"
                      depressed
                      large
                      to="/plants"
                    >
                      Explore
                    </v-btn>
                  </v-slide-x-transition>
                </v-overlay>
              </v-fade-transition>
              <center>
                <div class="pa-3 thirdary text-center white--text">
                  Plant guide
                </div>
              </center>
            </v-card>
          </template>
        </v-hover>
      </v-col>

      <!-- blockhouse -->
      <v-col
        cols="12"
        md="4"
      >
        <v-hover close-delay="20">
          <template v-slot="{ hover }">
            <v-card
              :elevation="hover ? 2 : 8"
              hover
            >
              <div class="pa-3">
                <v-img
                  :aspect-ratio="16 / 9"
                  :src="require('@/assets/Main/wholesale.webp')"
                  contain
                />
              </div>

              <v-fade-transition>
                <v-overlay
                  v-if="hover"
                  absolute
                  class="text-right"
                  color="rgb(230, 230, 230)"
                >
                  <v-slide-x-transition appear>
                    <v-btn
                      class="subheading font-weight-light text-capitalize mx-0 mt-3"
                      color="secondary"
                      depressed
                      large
                      to="/wholesale-nursery"
                    >
                      Explore
                    </v-btn>
                  </v-slide-x-transition>
                </v-overlay>
              </v-fade-transition>
              <center>
                <div class="pa-3 thirdary text-center white--text">
                  Wholesale Nursery
                </div>
              </center>
            </v-card>
          </template>
        </v-hover>
      </v-col>
    </v-row>
  </section>
</template>

<script>
  export default {
    data: () => ({})
  }
</script>
